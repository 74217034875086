@font-face {
  font-family: 'Couture';
  src: url('./couture/couture-bld-webfont.woff2') format('woff2'),
    url('./couture/couture-bld-webfont.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./quicksand/quicksand_bold-webfont.woff2') format('woff2'),
    url('./quicksand/quicksand_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: 700;
  letter-spacing: -0.1px;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./quicksand/quicksand_book-webfont.woff2') format('woff2'),
    url('./quicksand/quicksand_book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: 400;
  letter-spacing: -0.1px;
}